/* eslint-disable */
import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import { motion } from 'framer-motion'

// Components
import Arrow from '../components/arrow'
import CircleNav from '../components/circlenav'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Socials from '../components/socials'
import Footer from '../components/footer'

// Image and video assets
import LandingIndex from '../../static/videos/landing-index.mp4'
import LandingInvestment from '../../static/videos/landing-investment.mp4'
import LandingLearn from '../../static/videos/landing-learn.mp4'
// import LandingListen from "../../static/videos/landing-listen.mp4";

class Index extends Component {
  constructor(props) {
    super(props)
  }

  fadeInVideoLoaded(e) {
    e.target.classList.add('loaded')
  }

  render() {
    return (
      <>
        <Layout>
          <SEO
            title="Home"
            description={
              'Koji Capital is a venture capital firm cultivating community. We invest in early stage crypto projects enriching the Web3 ecosystem.'
            }
          />
          <style type="text/css">{`
            html,
            body {
              overflow: hidden !important;
            }
            ::-webkit-scrollbar-track {
              background: rgba(0, 0, 0, 1);
            }
            /* Show the footer that is inside the Pagepiling element
               but not the one outside of it */
            footer {
              display: none;
            }
            .contact footer {
              display: block;
            }

            /* Fix for the pagepiling element not aligning properly after
               coming from another page */
            nav ~ main {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100vh;
              overflow: hidden;
            }
          `}</style>
          <motion.div
            initial={{
              opacity: 0,
              x: -150,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            exit={{
              opacity: 0,
              x: -150,
            }}
            transition={{
              ease: 'easeOut',
              duration: 1.5,
            }}
            className="circle-animator"
          >
            <CircleNav location="home" />
          </motion.div>
          <motion.div
            initial={{
              opacity: 1,
              x: 0,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            exit={{
              opacity: 0,
              x: -150,
            }}
            transition={{
              ease: 'easeOut',
              duration: 1.5,
            }}
          >
            <div className="homepage-anim">
              <section
                className="pp-section banner active section-showcase light-nav"
                data-anchor="page1"
              >
                <div className="container">
                  <header>
                    <motion.h1
                      initial={{
                        opacity: 0,
                        x: 0,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                      }}
                      exit={{
                        opacity: 0,
                        x: -100,
                      }}
                      transition={{
                        ease: 'easeOut',
                        duration: 0.5,
                      }}
                    >
                      <span>Koji is a venture capital firm</span>
                      <Link className="link-white" data-menuanchor="thesis-page1" to="/about">
                        {' cultivating community.'}
                        <button role="button" className="circle-btn" />
                      </Link>
                    </motion.h1>
                  </header>
                  <Arrow down anchor="page2" type="orange" />
                </div>
                <video muted loop="loop" autoPlay playsInline>
                  <source src={LandingIndex} type="video/mp4" />
                </video>
              </section>

              <section className="pp-section section-showcase about" data-anchor="page2">
                <div className="container">
                  <header>
                    <h1>
                      <span>Cultivating Web 3.0</span>
                      <br />
                      <span>
                        We invest in projects that are enriching the Web 3.0 ecosystem for their
                        community.
                      </span>
                    </h1>
                    <Link className="link-white hover-black" to="/portfolio">
                      View our portfolio
                      <button role="button" className="circle-btn md" />
                    </Link>
                  </header>
                  <Arrow down anchor="page3" type="black" />
                </div>
                <video muted loop="loop" autoPlay playsInline>
                  <source src={LandingInvestment} type="video/mp4" />
                </video>
              </section>
              <section
                className="pp-section section-showcase highdef light-nav"
                data-anchor="page3"
              >
                <div className="container">
                  <header>
                    <h1>
                      <span>Cultivating conversations</span>
                      <br />
                      <span>
                        Hear from the founders, builders, and creators building Web3 from the ground
                        up.
                      </span>
                    </h1>
                    <Link className="link-white" to="/listen">
                      Listen to our podcast
                      <button role="button" className="circle-btn md" />
                    </Link>
                  </header>
                  <Arrow down anchor="page4" type="orange" />
                </div>
                <video muted loop="loop" autoPlay playsInline>
                  <source src={LandingLearn} type="video/mp4" />
                </video>
              </section>

              <section
                className="pp-section pp-scrollable section-showcase contact light-nav contrast-nav hide-circles-mobile"
                data-anchor="page4"
              >
                <div className="container">
                  <div className="left">
                    <Arrow up anchor="page3" type="orange" />
                    <header>
                      <span className="subheading">CONTACT</span>
                      <h1>Get in touch.</h1>
                    </header>
                    <Socials />
                  </div>
                  <div className="right">
                    <form
                      className="contact-form"
                      action="https://formspree.io/f/xrgryaoz"
                      method="POST"
                    >
                      <div className="form-item half">
                        <label htmlFor="First name">FIRST NAME</label>
                        <input placeholder="First name" name="first_name" type="text" required />
                      </div>
                      <div className="form-item half">
                        <label htmlFor="Last name">LAST NAME</label>
                        <input placeholder="Last name" name="last_name" type="text" required />
                      </div>
                      <div className="form-item">
                        <label htmlFor="Company name">COMPANY</label>
                        <input placeholder="Company name" name="company" type="text" required />
                      </div>
                      <div className="form-item">
                        <label htmlFor="Email address">EMAIL</label>
                        <input placeholder="Email address" name="email" type="email" required />
                      </div>
                      <div className="form-item">
                        <label htmlFor="Subject">SUBJECT</label>
                        <input
                          placeholder="Write your subject line here..."
                          name="subject"
                          type="text"
                          required
                        />
                      </div>
                      <div className="form-item">
                        <label htmlFor="Message">MESSAGE</label>
                        <textarea
                          placeholder="Write your message here..."
                          name="message"
                          required
                          defaultValue={''}
                        />
                      </div>
                      <button type="submit">SEND</button>
                    </form>
                  </div>
                  <Socials mobileSml />
                </div>
                <Footer />
              </section>
            </div>
          </motion.div>
        </Layout>
      </>
    )
  }
}

export default Index
